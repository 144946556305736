<template>
  <div>
    <b-overlay
      :show="show"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <IbTabs />
      <b-card>
        <b-row align-h="center">
          <b-col cols="12">
            <b-table
              class="table-sm"
              dir="ltr"
              responsive
              striped
              hover
              :items="items"
              :fields="headers"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BTable,
} from 'bootstrap-vue'
import IbTabs from './components/ibTabs.vue'
import PannelIbApis from '@/modules/IB/services/apis'
import { toFixed } from '@/utils'

const ib = new PannelIbApis()
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    IbTabs,
  },
  data() {
    return {
      items: [],
      headers: [{ lable: 'symbols', key: 'symbols' }],
      show: false,
    }
  },
  async mounted() {
    await this.getData()
  },
  methods: {
    async getData() {
      this.show = true
      await ib.commissions().then(res => {
        const response = res.data.results
        response.lots.forEach(lot => {
          this.headers.push({ label: lot.name, key: lot.name })
        })

        response.commission.forEach(com => {
          const data = {
            symbols: com.symbol,
          }
          com.lot.forEach(val => {
            data[val.lot] = `${val.commission.toFixed(2)} ${val.currency}`
          })

          this.items.push(data)
        })
      }).catch(error => {
        console.error(error)
      }).finally(() => {
        this.show = false
      })
    },
  },
}
</script>
